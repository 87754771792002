import React from "react";
import posthog from "posthog-js";

import "./src/styles/global.css";
import { AppContextProvider } from "./src/context/app-context";

export const onClientEntry = () => {
  posthog.init("phc_7lsfa5RMZRbRtcJIsFDp5FhYqNOJx6TnpWwBTohgSER", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "always",
  });
};

export const wrapRootElement = ({ element }) => (
  <AppContextProvider>{element}</AppContextProvider>
);
