import React from "react";
import posthog from "posthog-js";

const defaultState = {
  lightboxIsOpen: false,
  toggleLightbox: () => {},

  sidebarIsOpen: false,
  toggleSidebar: () => {},
};

const AppContext = React.createContext(defaultState);

class AppContextProvider extends React.Component {
  state = {
    lightboxIsOpen: false,
    sidebarIsOpen: false,
  };

  toggleLightbox = () => {
    const lightboxIsOpen = !this.state.lightboxIsOpen;
    this.setState({ lightboxIsOpen });

    if (lightboxIsOpen) {
      posthog.capture("lightbox-open");

      window &&
        window.gtag &&
        window.gtag("event", "screen_view", {
          screen_name: "appointment_modal",
        });

      // disable body scroll
      document.body.classList.add("overflow-hidden");
    } else {
      // enable body scroll
      document.body.classList.remove("overflow-hidden");
    }
  };

  toggleSidebar = () => {
    const sidebarIsOpen = !this.state.sidebarIsOpen;
    this.setState({ sidebarIsOpen });

    if (sidebarIsOpen) {
      window &&
        window.gtag &&
        window.gtag("event", "screen_view", {
          screen_name: "sidebar",
        });

      // disable body scroll
      document.body.classList.add("overflow-hidden");
    } else {
      // enable body scroll
      document.body.classList.remove("overflow-hidden");
    }
  };

  render() {
    const { children } = this.props;
    const { lightboxIsOpen, sidebarIsOpen } = this.state;
    return (
      <AppContext.Provider
        value={{
          lightboxIsOpen,
          toggleLightbox: this.toggleLightbox,
          sidebarIsOpen,
          toggleSidebar: this.toggleSidebar,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
}

export default AppContext;

export { AppContextProvider };
